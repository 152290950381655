import React, {useContext} from "react"
import { Link } from "gatsby"
import Template from "../components/template"
import "../styles/kooperationen.css"
import {kooperationenContent} from "../content/kooperationen"
import {SettingsContext} from "../contexts/settings"

const KooperationenPage = () => {
    const [settings, ] = useContext(SettingsContext)
    const content = kooperationenContent[settings.language]
    return (
        <Template currentPage="cooperations">
            <section>
                    <div>
                        <h1>{content.headline}</h1>
                        <h2>{content.secondHeadlineFirst}</h2>
                        
                        <div className="image-banner-top">
                            <Link className="coop-partner-wide" to="/kooperationspartner/femphils">
                                <img className="coop-partner" src="/logos/Kooperationspartner/Logo_femphils.png" alt={content.first}/>
                            </Link>
                            <Link className="coop-partner-wide" to="/kooperationspartner/ladiez">
                                <img className="coop-partner" src={settings.isHighContrast ? "/logos/Kooperationspartner/bw/inverted/bw_inverted_Logo_Ladiez.png" : "/logos/Kooperationspartner/bw/bw_Logo_Ladiez.png"} alt={content.second}/>
                            </Link>
                            <Link className="coop-partner-narrow" to="/kooperationspartner/mousonturm">
                                <img className="coop-partner" src={settings.isHighContrast ? "/logos/Kooperationspartner/bw/inverted/bw_inverted_Logo_Mousonturm.png" : "/logos/Kooperationspartner/bw/bw_Logo_Mousonturm.png"} alt={content.third}/>
                            </Link>
                            <Link className="coop-partner-narrow" to="/kooperationspartner/hafen2">
                                <img className="coop-partner" src={settings.isHighContrast ? "/logos/Kooperationspartner/bw/inverted/bw_inverted_Logo_Hafen2.png" : "/logos/Kooperationspartner/bw/bw_Logo_Hafen2.png"} alt={content.fourth}/>
                            </Link>
                            
                        </div>
                        <h2>{content.secondHeadlineSecond}</h2>
                        <div className="image-banner-middle">
                            <div className="fonds-soziokultur-container">
                                <a className="coop-partner-wide" href="https://www.fonds-soziokultur.de/foerderung/foerderprogramme/sonderprogramm-neustart-kultur.html" target="_blank" rel="noopener noreferrer">
                                    <img className="coop-partner" src={settings.isHighContrast ? "/logos/Kooperationspartner/bw/inverted/bw_inverted_Logo_Fonds-Soziokultur.png" : "/logos/Kooperationspartner/bw/bw_Logo_Fonds-Soziokultur.png"} alt={content.fifth} />
                                    <div className="fonds-soziokultur-second-container">
                                        <img className="logo-neustart" src={settings.isHighContrast ? "/logos/Kooperationspartner/bw/inverted/bw_inverted_Logo_Neustart-Kultur.png" : "/logos/Kooperationspartner/bw/bw_Logo_Neustart-Kultur.png"} alt={content.sixth}/>
                                        <img className="logo-beauftragter-breg" src={settings.isHighContrast ? "/logos/Kooperationspartner/bw/inverted/bw_inverted_Logo_Beauftragter-BReg-Kultur-und-Medien.png" : "/logos/Kooperationspartner/bw/bw_Logo_Beauftragter-BReg-Kultur-und-Medien.png"} alt={content.seventh}/>
                                    </div>
                                </a>
                            </div>
                            <a className="coop-partner-wide" href="https://wissenschaft.hessen.de/foerderung/kulturfoerderung/corona-kulturpaket/phase-3b-projektstipendien" target="_blank" rel="noopener noreferrer">
                                <img className="coop-partner" src={settings.isHighContrast ? "/logos/Kooperationspartner/bw/inverted/bw_inverted_Logo_Hess-Min-Wissenschaft-und-Kultur.png" : "/logos/Kooperationspartner/bw/bw_Logo_Hess-Min-Wissenschaft-und-Kultur.png"} alt={content.eighth} />
                            </a>
                            <a className="coop-partner-wide" href="https://www.uni-frankfurt.de/38656007/F%C3%B6rderfonds_Lehre" target="_blank" rel="noopener noreferrer">
                                <img className="coop-partner" src={settings.isHighContrast ? "/logos/Kooperationspartner/bw/inverted/bw_inverted_Logo_Goethe-Universitaet-Frankfurt-am-Main.png" : "/logos/Kooperationspartner/bw/bw_Logo_Goethe-Universitaet-Frankfurt-am-Main.png"} alt={content.ninth} />
                            </a>
                            <a className="coop-partner-wide" href="https://frankfurt.de/service-und-rathaus/verwaltung/aemter-und-institutionen/stabsstelle-inklusion" target="_blank" rel="noopener noreferrer">
                                <img className="coop-partner" src={settings.isHighContrast ? "/logos/Kooperationspartner/bw/inverted/bw_inverted_Logo_Stabstelle-Inklusion.png" : "/logos/Kooperationspartner/bw/bw_Logo_Stabstelle-Inklusion.png"} alt={content.tenth} />
                            </a>
                        </div>
                        <div className="image-banner-lower">
                            <a className="coop-partner-narrow-down" href="https://frankfurt.de/de-de/service-und-rathaus/verwaltung/aemter-und-institutionen/frauenreferat" target="_blank" rel="noopener noreferrer">
                                <img className="coop-partner" src={settings.isHighContrast ? "/logos/Kooperationspartner/bw/inverted/bw_inverted_Logo_Frauenreferat-Frankfurt-am-Main.png" : "/logos/Kooperationspartner/bw/bw_Logo_Frauenreferat-Frankfurt-am-Main.png"} alt={content.eleventh} />
                            </a>
                            <a className="coop-partner-narrow-down" href="https://hessische-theaterakademie.de/de/" target="_blank" rel="noopener noreferrer">
                                <img className="coop-partner" src={settings.isHighContrast ? "/logos/Kooperationspartner/bw/inverted/bw_inverted_Logo_Hessische-Theaterakademie.png" : "/logos/Kooperationspartner/bw/bw_Logo_Hessische-Theaterakademie.png"} alt={content.twelfth} />
                            </a>
                            <a className="coop-partner-wide-down" href="https://asta-frankfurt.de/" target="_blank" rel="noopener noreferrer">
                                <img className="coop-partner" src={settings.isHighContrast ? "/logos/Kooperationspartner/bw/inverted/bw_inverted_Logo_ASTA-Uni-Ffm.png" : "/logos/Kooperationspartner/bw/bw_Logo_ASTA-Uni-Ffm.png"} alt={content.thirteenth}/>
                            </a>
                            <a className="coop-partner-narrow-down" href="https://www.crespo-foundation.de" target="_blank" rel="noopener noreferrer">
                                <img className="coop-partner" src={settings.isHighContrast ? "/logos/Kooperationspartner/bw/inverted/Logo_Crespo-foundation_schwarz.png" : "/logos/Kooperationspartner/bw/Logo_ Crespo-foundation_weiß.svg"} alt={content.thirteenth}/>
                            </a>
                        </div> 
                        <h2>{content.secondHeadlineThird}</h2> 
                        <div className="image-banner-single">
                            <a className="coop-partner-wide-single end-of-site" href="https://www.lokay.de/" target="_blank" rel="noopener noreferrer">
                                <img className="coop-partner" src={settings.isHighContrast ? "/logos/Kooperationspartner/bw/inverted/bw_inverted_Logo_lokay.png" : "/logos/Kooperationspartner/bw/bw_Logo_lokay.png"} alt={content.fourteenth}/>
                            </a>
                        </div>
                    </div>
                </section>
        </Template>
    )
}
    
export default KooperationenPage