const kooperationenContent = {
    german: {
        headline: "PARTNER:INNEN",
        secondHeadlineFirst: "in Kooperation mit",
        secondHeadlineSecond: "gefördert durch",
        secondHeadlineThird: "Druck gesponsert von",
        first: "Startseite feministische Philosoph_innen Frankfurt",
        second: "Startseite Ladiez e.V.",
        third: "Startseite Künstlerhaus Mousonturm",
        fourth: "Startseite Hafen 2\"",
        fifth: "Startseite Fonds Soziokultur",
        sixth: "Logo Neustart Kultur",
        seventh: "Logo Beauftragter der Bundesregierung für Kultur und Medien",
        eighth: "Startseite Hessisches Ministerium für Wissenschaft und Kultur",
        ninth: "Startseite Goethe Universität Frankfurt",
        tenth: "Startseite Stabstelle Inklusion Frankfurt",
        eleventh: "Startseite Frauenreferat Frankfurt",
        twelfth: "Startseite Hessische Theaterakademie",
        thirteenth: "Startseite AStA Uni Frankfurt",
        fourteenth: "Startseite lokay",

    },
    english: {
        headline: "PARTNERS",
        secondHeadlineFirst: "in Cooperation with",
        secondHeadlineSecond: "funded by",
        secondHeadlineThird: "Print sponsored by",
        first: "home page feministische Philosoph_innen Frankfurt",
        second: "home page Ladiez e.V.",
        third: "home page Künstlerhaus Mousonturm",
        fourth: "home page Hafen 2",
        fifth: "home page Fonds Soziokultur",
        sixth: "Logo Neustart Kultur",
        seventh: "Logo Beauftragter der Bundesregierung für Kultur und Medien",
        eighth: "home page Hessisches Ministerium für Wissenschaft und Kultur",
        ninth: "home page Goethe Universität Frankfurt",
        tenth: "home page Stabstelle Inklusion Frankfurt",
        eleventh: "home page Frauenreferat Frankfurt",
        twelfth: "home page Hessische Theaterakademie",
        thirteenth: "home page AStA Uni Frankfurt",
        fourteenth: "home page lokay",


    }
}
export {kooperationenContent}